export const environment = {
  production: true,
  entityId: 1,
  apiUrl: "https://app-api.imodigi.com",
  imgApi: "https://admin-api.imodigi.com",
  template: 'template1',
  mainColor: '#38C',
  secondColor: '',
  headerColor: '#38CC',
  chatUrl: 'https://app-api.imodigi.com',
  notifictionPath: '/real-time/socket.io',
  notificationUrl: 'https://app-api.imodigi.com',
  homeTexts: true
};
